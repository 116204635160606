import { useLocation, useParams } from '@reach/router';

import { ErrorContextProvider } from '@xing-com/crate-entity-pages-common';
import { SubpageContainer } from '@xing-com/crate-entity-pages-subpage';
import type { Xinglet } from '@xing-com/crate-xinglet';

import { AboutUsSubpage } from './about-us-subpage';

export default class ArticleViewer implements Xinglet {
  public getComponent() {
    return (): JSX.Element => {
      const { slug, param } = useParams();
      const location = useLocation();

      const isWebview = /^\/pages\/webview/.test(location.pathname);

      return (
        <ErrorContextProvider>
          <SubpageContainer
            pageSlug={slug}
            pageType="about_us"
            param={param}
            isWebview={isWebview}
          >
            <AboutUsSubpage />
          </SubpageContainer>
        </ErrorContextProvider>
      );
    };
  }
}
