import { useNavigate } from '@reach/router';
import type { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, TextButton } from '@xing-com/button';
import { usePageContext } from '@xing-com/crate-entity-pages-common';
import type { PageContextType } from '@xing-com/crate-entity-pages-common';
import { trackBackToMainPage } from '@xing-com/crate-entity-pages-common/src/tracking';
import { IconArrowLeft } from '@xing-com/icons';

import * as Styled from './subpage-back-button.styles';

export const backToOverview = (navigate: any, pageContext: PageContextType) => {
  navigate(pageContext.basePath + '/' + pageContext.pageSlug);
};

export const SubpageTopBack: FC = () => {
  const navigate = useNavigate();
  const { pageContext } = usePageContext();

  return (
    <TextButton
      size={'medium'}
      icon={IconArrowLeft}
      onClick={() => {
        if (pageContext) backToOverview(navigate, pageContext);
        trackBackToMainPage();
      }}
      data-cy="back_to_overview"
    >
      <FormattedMessage
        id={'EP_SUBPAGE_BACK'}
        defaultMessage={'EP_SUBPAGE_BACK'}
      />
    </TextButton>
  );
};

export const SubpageBottomBack: FC = () => {
  const navigate = useNavigate();
  const { pageContext } = usePageContext();

  return (
    <Styled.BottomBack>
      <Button
        size={'medium'}
        variant={'tertiary'}
        onClick={() => pageContext && backToOverview(navigate, pageContext)}
      >
        <FormattedMessage
          id={'EP_SUBPAGE_BACK'}
          defaultMessage={'EP_SUBPAGE_BACK'}
        />
      </Button>
    </Styled.BottomBack>
  );
};
