import type { FC } from 'react';

import { usePageContext } from '@xing-com/crate-entity-pages-common';
// eslint-disable-next-line monorepo/forbidden-imports
import { TopBar as DefaultTopBar } from '@xing-com/top-bar';

export const TopBar: FC = () => {
  const { pageContext } = usePageContext();

  return (
    <DefaultTopBar
      title={pageContext.moduleTitle ?? 'XING'}
      subTitle={pageContext.pageTitle ?? undefined}
      type="subheader"
      skipAdditionalButtons
    />
  );
};
