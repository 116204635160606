import { useQuery } from '@apollo/client';
import { Redirect, useLocation, useNavigate } from '@reach/router';
import type { FC, PropsWithChildren } from 'react';
import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

import { ClickReasonsEnum } from '@xing-com/crate-common-graphql-types';
import {
  PageContextProvider,
  EntitySubpageDocument,
  MetaData,
  PageError,
  EditContextProvider,
  createEntityPageShared,
  PageLayout,
  Appcues,
  useTrackingVisitor,
} from '@xing-com/crate-entity-pages-common';
import { trackPageDetail } from '@xing-com/crate-entity-pages-common/src/tracking';
import { Header } from '@xing-com/crate-entity-pages-header';

import { subpagesConfig } from '../../config/subpages-config';
import { EP_URL } from '../../config/urls';
import { getPathByFocusType } from '../../utils/get-path-by-focus-type';

import { SubpageLayout } from './subpage-layout';
import * as Styled from './subpage.styles';

interface SubpageContainerProps {
  pageSlug: string;
  pageType: string;
  param?: string;
  isWebview?: boolean;
}
export const SubpageContainer: FC<PropsWithChildren<SubpageContainerProps>> = ({
  pageSlug,
  pageType,
  param,
  isWebview,
  children,
}) => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const { trackVisitor } = useTrackingVisitor({ pageSlug });

  useEffect(() => {
    trackVisitor();
  }, []);

  const trackSentRef = useRef(false);

  const seoIndexSubpage = subpagesConfig[pageType]?.seoIndexSubpage ?? true;

  const path = location.pathname?.split('/')[1];

  const { data, loading, error } = useQuery(EntitySubpageDocument, {
    variables: {
      id: pageSlug,
      socialProofClickReasonsKey:
        ClickReasonsEnum.CrWebPublisherSocialProofHeader,
      moduleType: pageType,
    },
    errorPolicy: 'all',
    skip: !!param,
    onCompleted: (data) => {
      if (
        data.entityPageEX?.__typename === 'EntityPage' &&
        data.entityPageEX?.publicationStatus === 'CREATED'
      ) {
        window.location.assign(`/pages/new/company`);
      }

      if (
        !trackSentRef.current &&
        data.entityPageEX?.__typename === 'EntityPage' &&
        data.entityPageEX?.id
      ) {
        const trackIsFollower: boolean =
          data.entityPageEX.userPageContext?.userInteractions?.__typename ===
          'EntityPageUserInteractionFollow'
            ? Boolean(
                data.entityPageEX.userPageContext?.userInteractions.followState
                  ?.isFollowing
              )
            : false;

        trackPageDetail({
          pageId: data.entityPageEX.id.split('.')[0],
          subpage: pageType,
          // @ts-expect-error TODO: fix this type
          isEditor: data.entityPageEX.userPageContext?.permissions.canEdit,
          // @ts-expect-error TODO: fix this type
          focusType: data.entityPageEX.focusType,
          contractType: data.entityPageEX.contract.type,
          isFollowing: trackIsFollower,
        });

        trackSentRef.current = true;
      }
    },
  });

  if (loading) {
    return (
      <PageLayout isWebview={isWebview}>
        <Styled.PageWrapper>
          <MetaData setDefault={true} title={'XING'} index={seoIndexSubpage} />
          <Header.Skeleton />
        </Styled.PageWrapper>
      </PageLayout>
    );
  }

  if (error || !data) {
    return <PageError type={500} />;
  }

  const entityPage = data.entityPageEX;

  if (!entityPage) {
    return <PageError type={500} />;
  }

  if (entityPage.__typename === 'EntityPageMoved') {
    return <Redirect to={`/${path}/${entityPage.slug}`} />;
  }

  if (entityPage.__typename === 'EntityPageError') {
    const type = entityPage.errorCode === 10404 ? 404 : 503;

    return <PageError type={type} />;
  }

  if (entityPage.__typename !== 'EntityPage') {
    return <PageError type={500} />;
  }

  const moduleProperties = data.moduleProperties;

  if (!moduleProperties) {
    return <PageError type={404} />;
  }

  const basePath = getPathByFocusType();

  if (basePath !== `/${path}`) {
    return <PageError type={404} />;
  }

  const moduleGlobalId =
    moduleProperties.__typename === 'EntityPageGenericModule' &&
    moduleProperties.globalId;

  const sharedEntityPageProperties = createEntityPageShared(
    entityPage,
    isWebview
  );

  return (
    <PageContextProvider
      initialValue={{
        ...sharedEntityPageProperties,
        // Extra
        moduleTitle: formatMessage({
          id: `EP_${pageType.toUpperCase()}_HEADLINE`,
          defaultMessage: `EP_${pageType.toUpperCase()}_HEADLINE`,
        }),
        isWebview,
        pageModulesByType: [],
        pageModules: [],
        goBackUrl: entityPage.globalId
          ? () => navigate(-1)
          : () => navigate(EP_URL(entityPage.slug)),
      }}
    >
      <EditContextProvider>
        <Appcues>
          <SubpageLayout
            isWebview={isWebview}
            pageType={pageType}
            moduleGlobalId={moduleGlobalId ?? undefined}
          >
            {children}
          </SubpageLayout>
        </Appcues>
      </EditContextProvider>
    </PageContextProvider>
  );
};
