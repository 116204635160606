import type { FC } from 'react';

import {
  ModuleHeader,
  EmptyState,
  UpsellBanner,
  usePageContext,
} from '@xing-com/crate-entity-pages-common';

import { Documents } from './documents';
import EmptyStateIcon from './empty-state-icon.svg';

type DocumentsArray = {
  description?: string;
  documentUrl: string;
  filename: string;
}[];

type DocumentsContainerProps = {
  documents: DocumentsArray;
};

type RenderDocumentsProps = {
  documents: DocumentsArray;
  upsellActive?: boolean;
};

const renderDocuments = ({ documents, upsellActive }: RenderDocumentsProps) => {
  if (upsellActive) {
    return (
      <UpsellBanner
        headerCopyKey="EP_UPSELL_DOCUMENTS_HEADER"
        bodyCopyKey="EP_UPSELL_DOCUMENTS"
        moduleName="aboutusmodule"
      />
    );
  } else {
    if (documents.length > 0) {
      return <Documents documents={documents} />;
    } else {
      return (
        <EmptyState
          icon={EmptyStateIcon}
          headlineCopyKey={'EP_NO_DOCUMENTS_HEADLINE'}
          textCopyKey={'EP_NO_DOCUMENTS'}
        />
      );
    }
  }
};
export const DocumentsContainer: FC<DocumentsContainerProps> = ({
  documents,
}) => {
  const { pageContext } = usePageContext();
  const isFreePage = pageContext.contractType === 'FREE';
  const upsellActive = pageContext.upsellActive;

  return (
    <div data-testid="aboutus-detail-documents">
      <ModuleHeader
        headlineCopyKey="EP_DOCUMENTS_HEADLINE"
        editTo={
          !isFreePage
            ? `${pageContext.basePath}/${pageContext.pageSlug}/edit/documents`
            : null
        }
        editTestId="edit-documents"
      />
      {renderDocuments({ documents, upsellActive })}
    </div>
  );
};
