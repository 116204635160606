import cx from 'classnames';
import type { FC, PropsWithChildren } from 'react';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

import {
  type PageViewTrackingEvent,
  useTracking,
} from '@xing-com/crate-communication-tracking';
import type { MetaDataType } from '@xing-com/crate-entity-pages-common';
import {
  MetaData,
  ModuleContextProvider,
  ErrorBoundary,
  usePageContext,
  PageError,
  useEditContext,
  CompanyBannersContainer,
  CustomerFeedBackButton,
  PageLayout,
  EditBar,
} from '@xing-com/crate-entity-pages-common';
import { getNwtTrackingProps } from '@xing-com/crate-entity-pages-common/src/tracking';
import { Header } from '@xing-com/crate-entity-pages-header';
import { useFeatureSwitch } from '@xing-com/hub';
import { GridContainer } from '@xing-com/xing-grid';

import {
  SubpageBottomBack,
  SubpageTopBack,
} from '../../components/subpage-back-button/subpage-back-button';
import { TopBar } from '../../components/top-bar/top-bar';
import { subpagesConfig } from '../../config/subpages-config';

import * as Styled from './subpage.styles';

type SubpageLayoutProps = {
  isWebview?: boolean;
  pageType: string;
  moduleGlobalId?: string | false;
};
export const SubpageLayout: FC<PropsWithChildren<SubpageLayoutProps>> = ({
  isWebview,
  pageType,
  moduleGlobalId,
  children,
}) => {
  const { formatMessage } = useIntl();
  const { pageContext } = usePageContext();
  const { isEditing } = useEditContext();
  const { track: trackPageView } =
    useTracking<PageViewTrackingEvent<Record<string, unknown>>>();

  const hasSubpage = !!subpagesConfig[pageType];
  const seoIndexSubpage = subpagesConfig[pageType]?.seoIndexSubpage ?? true;

  const canEditMobile = useFeatureSwitch('ep_mobileEdit', false);

  const isCompanyDraft =
    pageContext.isCompanyPage && pageContext.publicationStatus === 'DRAFT';

  const canEditOnMobile = pageContext.isEditor && pageType === 'news';

  const metaData: MetaDataType = {
    title: `${formatMessage(
      {
        id: `EP_META_${pageType.toUpperCase()}_TITLE`,
      },
      { pageTitle: pageContext.pageTitle }
    )}`,
    url: `${pageContext.url}/${pageType}`,
    image: pageContext.logo,
    description:
      pageType !== 'about_us'
        ? formatMessage(
            {
              id: `EP_META_${pageType.toUpperCase()}_DESCRIPTION`,
            },
            { pageTitle: pageContext.pageTitle }
          )
        : null,
  };

  useEffect(() => {
    trackPageView({
      type: 'nwt',
      page: `entity_pages/subpage/${pageType}`,
      application: 'wbm',
      event: 'viewed_screen',
      eventSchema: 'basic',
      sentBy: 'entity_pages',
      ...getNwtTrackingProps({ pageContext, isEditing }),
    });
  }, []);

  if (!hasSubpage) {
    return (
      <div>
        <PageError type={404} />
      </div>
    );
  }

  return (
    <PageLayout isWebview={isWebview}>
      <TopBar />

      <Styled.PageWrapper data-testid="subpage-layout">
        <MetaData setDefault={true} index={seoIndexSubpage} {...metaData} />

        {pageContext.isEditor && pageContext.isCompanyPage && (
          <CompanyBannersContainer
            companyId={pageContext.companyId}
            isDraft={isCompanyDraft}
          />
        )}
        {pageContext.isEditor && <CustomerFeedBackButton module={pageType} />}

        <div className={cx({ isWebview })}>
          {!isWebview && pageContext.isEditor && (
            <Styled.EditBarSubpage>
              <EditBar
                displayNewsButtons={false}
                canEditOnMobile={canEditOnMobile || canEditMobile}
                displayBackButton={true}
              />
            </Styled.EditBarSubpage>
          )}

          <Styled.GridWrapper>
            {!pageContext.isEditor && !isWebview && (
              <GridContainer>
                <Styled.Row>
                  <Styled.Column size={12}>
                    <SubpageTopBack />{' '}
                  </Styled.Column>
                </Styled.Row>
              </GridContainer>
            )}
          </Styled.GridWrapper>

          <Styled.HeaderWrapper>
            <Header
              pageType={pageType}
              moduleType={pageType}
              isSubpage={true}
            />
          </Styled.HeaderWrapper>

          <GridContainer>
            <Styled.Row>
              <Styled.Column isModuleContainer size={12}>
                <ModuleContextProvider
                  value={{
                    moduleType: pageType,
                    moduleGlobalId: moduleGlobalId,
                    isWebview,
                  }}
                >
                  <ErrorBoundary>
                    {/* <SubPageModule {...pageContext} /> */}
                    {children}
                  </ErrorBoundary>
                </ModuleContextProvider>
              </Styled.Column>
            </Styled.Row>
          </GridContainer>

          {!isWebview && <SubpageBottomBack />}
        </div>
      </Styled.PageWrapper>
    </PageLayout>
  );
};
